export const key = '3bP:iciPglHljrTnGsSaww8TmHgtsddiuJBrRgmdLb8';
export const firebasesecret =
  '{"iv":"IY1tNHwuk2uhG46Ecne3RQ==","v":1,"iter":10000,"ks":128,"ts":64,"mode":"ccm","adata":"","cipher":"aes","salt":"lSE1jsdWfUk=","ct":"va3DPJq79RurC2FDl0zfKnsQLzel7s28WpMzlIx0NyH/a+0dS1UI0IUZq4+T9fdv1yyhjZXVQWrrgra0sw0XtBpHuAQ0kK+y10UVNMt7hwilrkhUkDTyEeUzsMTXuG8kUzDjoeIXfg5d4E7ddkCKsaeNxm0SKYXT3Xmnx68tvJqW6o+D/B6fezCT5tMDONnn5aNYQw8uZJ5lTo9sp9mrS0ddMH4zrX9KXBxQET/vuwluQ/YQRV/2Ai4RRuq5pf5XIvyNYwaFfQPc62mTR1hegMkhd91kTW3NylVQAGwNcfpY9WbGHDZNG2982FyW9zHErM1PgUx3fV+x"}';

export const firebaseConfig = {
  apiKey: 'AIzaSyBkkwtQiIealyyv65xtF7NfjS64xz2ZB6w',
  authDomain: 'safepass-8bf08.firebaseapp.com',
  databaseURL: 'https://safepass-8bf08.firebaseio.com',
  projectId: 'safepass-8bf08',
  storageBucket: 'safepass-8bf08.appspot.com',
  messagingSenderId: '859093043262',
  appId: '1:859093043262:web:27dcc6410126e9338f296f',
};
